import React, { useState, useContext } from "react";
import { Modal } from "react-bootstrap";
import { useLocation } from "react-router-dom";

import "../../styles/Sidebar.css";

import { PermissionsContext } from "../Auth/AuthorizationProvider";
import ContactMe from "../Home/ContactMe";
import { observer } from "mobx-react-lite";
import useRootStore from "../../hooks/rootStoreHook";
import Logo from "../Common/Logo";
import SidebarItem from "./SidebarItem";
import {
  ArchiveBookIcon,
  BookSquareIcon,
  BoxIcon,
  ConvertThreeDCube,
  FolderCloudIcon,
  Setting2Icon,
  DashboardIcon,
  TaskSquareIcon,
  TeacherIcon,
  WarningIcon,
  LogOutIcon,
} from "../../assets/icons";

// const SpaceSelector = lazyWithRetry(() => import("../Admin/SpaceSelector"));

export default observer(function Sidebar() {
  let { pathname } = useLocation();
  let adminActive = pathname.startsWith("/admin");
  const { apiClient, sessionStore } = useRootStore();
  const [viewContactModal, setViewContactModal] = useState(false);
  const [notificationsCount, setNotificationsCount] = useState<{
    hidden: number;
    total: number;
    visible: number;
  }>({ hidden: 0, total: 0, visible: 0 });
  const [tasksCount, setTasksCount] = useState<{ total: number }>({ total: 0 });
  const [expandedMenu, setExpandedMenu] = useState<string | null>(null);

  const handleMenuClick = (name: string) => {
    setExpandedMenu((prev) => (prev === name ? null : name));
  };

  const permissionsContext = useContext(PermissionsContext);

  // const getNotificationsCount = useCallback(
  //   function () {
  //     apiClient.get("notifications/count").then((a) => {
  //       setNotificationsCount(Unwrap.result(a));
  //     });
  //   },
  //   [apiClient]
  // );

  // const getTasksCount = useCallback(
  //   function () {
  //     apiClient.get("Tasks/count").then((a) => {
  //       setTasksCount(Unwrap.result(a));
  //     });
  //   },
  //   [apiClient]
  // );

  // useEffect(() => {
  //   window.addEventListener("notifications.changed", getNotificationsCount);

  //   return () => {
  //     window.removeEventListener(
  //       "notifications.changed",
  //       getNotificationsCount
  //     );
  //   };
  // }, [getNotificationsCount]);

  // useEffect(() => {
  //   window.addEventListener("tasks.changed", getTasksCount);

  //   return () => {
  //     window.removeEventListener("tasks.changed", getTasksCount);
  //   };
  // }, [getTasksCount]);

  // useEffect(() => {
  //   if (!sessionStore.currentSpace) {
  //     setNotificationsCount({ hidden: 0, total: 0, visible: 0 });
  //     return;
  //   }
  //   getNotificationsCount();
  // }, [getNotificationsCount, sessionStore.currentSpace]);

  // useEffect(() => {
  //   if (!sessionStore.currentSpace) {
  //     setTasksCount({ total: 0 });
  //     return;
  //   }
  //   getTasksCount();
  // }, [getTasksCount, sessionStore.currentSpace]);

  return (
    <aside
      className="aside-container"
      style={{ transition: "opacity 0.5s ease 0s", opacity: "1" }}
    >
      <div className="aside-inner">
        <nav
          data-sidebar-anyclick-close=""
          className="sidebar d-flex flex-column"
        >
          <Logo />
          <ul className="sidebar-nav flex-grow-1">
            <SidebarItem name="Dashboard" path="/" icon={<DashboardIcon />} />
            {!!sessionStore.license ? (
              <>
                <SidebarItem
                  name="Compliance Library"
                  icon={<BookSquareIcon />}
                  isExpanded={expandedMenu === "ComplianceLibrary"}
                  onClick={() => handleMenuClick("ComplianceLibrary")}
                  menu={[
                    ...(process.env.REACT_APP_ENVIRONMENT !== "production"
                      ? [{ name: "Legislations", path: "/legislations" }]
                      : []),
                    {
                      name: "Standards",
                      path: "tools/standards",
                    },
                    {
                      name: "Global markings",
                      path: "/tools/global-markings",
                    },
                    {
                      name: "Global legislation finder",
                      path: "/tools/global-legislation-finder",
                    },
                    {
                      name: "Global standard finder",
                      path: "/tools/standard-finder",
                    },
                    {
                      name: "EU Legislation Finder",
                      path: "tools/legislation-finder",
                    },
                    {
                      name: "News",
                      path: "/news",
                    },
                  ]}
                />
                <SidebarItem
                  name="Inventory"
                  isExpanded={expandedMenu === "Inventory"}
                  onClick={() => handleMenuClick("Inventory")}
                  icon={<BoxIcon />}
                  menu={[
                    {
                      name: "Product list",
                      path: "/products",
                    },
                    {
                      name: "Manual import",
                      path: "/admin/admin/import",
                    },
                    {
                      name: "Templates",
                      path: "/admin/admin/product-templates",
                    },
                    {
                      name: "Custom fields",
                      path: "/admin/admin/custom-product-fields",
                    },
                    {
                      name: "Integration",
                      path: "/integration",
                    },
                  ]}
                />
                <SidebarItem
                  name="Workflow"
                  isExpanded={expandedMenu === "Workflow"}
                  onClick={() => handleMenuClick("Workflow")}
                  icon={<TaskSquareIcon />}
                  menu={[
                    {
                      name: "Roadmaps",
                      path: "/admin/admin/custom-roadmaps",
                    },
                    {
                      name: "Tasks",
                      path: "/admin/admin/group-tasks",
                    },
                    {
                      name: "Activity Log",
                      path: "/activity-log",
                    },
                  ]}
                />
                <SidebarItem
                  name="Documentation"
                  icon={<FolderCloudIcon />}
                  isExpanded={expandedMenu === "Documentation"}
                  onClick={() => handleMenuClick("Documentation")}
                  menu={[
                    {
                      name: "Documentation requirements",
                      path: "/tools/documentation-requirements",
                    },
                    {
                      name: "Document manager",
                      path: "/tools/documentation-manager",
                    },
                    {
                      name: "Document generator",
                      path: "tools/documentation-generator",
                    },
                  ]}
                />
                {process.env.REACT_APP_ENVIRONMENT !== "production" ? (
                  <>
                    <SidebarItem
                      name="Declaration Manager"
                      path="/declaration-manager"
                      icon={<ArchiveBookIcon />}
                    />
                    <SidebarItem
                      name="Supplier Portal"
                      path="/supplier-portal"
                      icon={<ConvertThreeDCube />}
                    />
                    <SidebarItem
                      name="Risk Monitor"
                      path="/risk-monitor"
                      icon={<WarningIcon />}
                    />
                    <SidebarItem
                      name="Training & Resources"
                      path="/training-resources"
                      icon={<TeacherIcon />}
                    />
                  </>
                ) : null}
                <SidebarItem
                  name="Management"
                  path="/admin/admin/admin-page"
                  icon={<Setting2Icon />}
                />

                {/* {!permissionsContext?.state.userPerm.products ? (
                  <li className="">
                    <NavLink
                      to={"/products"}
                      title="Products"
                      className={"d-flex align-items-center font-medium"}
                    >
                      <ProductsIcon />
                      <span className="w-100 ml-3">Products</span>
                    </NavLink>
                  </li>
                ) : null} */}
                {/* <li className="">
                  <NavLink
                    to="/tasks"
                    title="Tasks"
                    style={{ whiteSpace: "normal" }}
                    className={"d-flex align-items-center font-medium"}
                  >
                    <TaskIcon />
                    <span className="ml-3 w-100">
                      Tasks
                      {tasksCount.total > 0 ? (
                        <Badge
                          className="d-inline-block float-right"
                          color="primary"
                        >
                          {tasksCount.total}
                        </Badge>
                      ) : null}
                    </span>
                  </NavLink>
                </li>
                <li className="">
                  <NavLink
                    to={"/notifications"}
                    title="Notifications"
                    style={{ whiteSpace: "normal" }}
                    className={"d-flex align-items-center font-medium"}
                  >
                    {notificationsCount.visible > 0 ? (
                      <NotificationIcon />
                    ) : (
                      <NotificationIcon />
                    )}
                    <span className="ml-3 w-100">
                      Notifications
                      {notificationsCount.visible > 0 ? (
                        <Badge className="d-inline-block float-right badge bg-primary">
                          {notificationsCount.visible}
                        </Badge>
                      ) : null}
                    </span>
                  </NavLink>
                </li>
                {sessionStore.license?.licensePermissions?.tools === true ? (
                  <li>
                    <NavLink
                      to={"/tools"}
                      title="Tools"
                      style={{ whiteSpace: "normal" }}
                      className={"d-flex align-items-center font-medium"}
                    >
                      <ToolsIcon />
                      <span className="ml-3">Tools</span>
                    </NavLink>
                  </li>
                ) : null}
                <li>
                  <NavLink
                    to={"/news"}
                    title="News"
                    className={"d-flex align-items-center font-medium"}
                  >
                    <NewsIcon />
                    <span className="ml-3">News</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={"/admin/admin/users/users"}
                    title="Admin"
                    style={{ whiteSpace: "normal" }}
                    className={
                      adminActive
                        ? "d-flex align-items-center font-medium active"
                        : "d-flex align-items-center font-medium"
                    }
                  >
                    <SettingsIcon />
                    <span className="ml-3">Admin</span>
                  </NavLink>
                </li>
              </> */}
              </>
            ) : null}
            {/* <li>
              <NavLink
                to={"/store"}
                title="Store"
                style={{ whiteSpace: "normal" }}
                className="d-flex align-items-center font-medium open-store"
              >
                <StoreIcon />
                <span className="ml-3">Store</span>
              </NavLink>
            </li> */}
            {!sessionStore.license ? (
              <>
                <li className="mt-3">
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      setViewContactModal(true);
                    }}
                    className="font-weight-bold"
                    style={{ whiteSpace: "normal" }}
                  >
                    <span
                      aria-label="Alert Octagram icon"
                      role="img"
                      className="material-design-icon alert-octagram-icon icon text-purple"
                    >
                      <svg
                        fill="var(--sidebar-icon)"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        className="material-design-icon__svg"
                      >
                        <path d="M2.2,16.06L3.88,12L2.2,7.94L6.26,6.26L7.94,2.2L12,3.88L16.06,2.2L17.74,6.26L21.8,7.94L20.12,12L21.8,16.06L17.74,17.74L16.06,21.8L12,20.12L7.94,21.8L6.26,17.74L2.2,16.06M13,17V15H11V17H13M13,13V7H11V13H13Z">
                          <title>Alert Octagram icon</title>
                        </path>
                      </svg>
                    </span>
                    <span className="text-sidebar-foreground">
                      Get more - upgrade
                    </span>
                  </a>
                </li>
              </>
            ) : null}
          </ul>
          {!!sessionStore.license ? (
            <button
              className="d-flex bg-sidebar text-sidebar-foreground border-0 ml-3 mt-5 "
              onClick={() => {
                sessionStore.signOut();
              }}
            >
              <LogOutIcon />
              <p className="ml-3 font-weight-bold text-base">Sign out</p>
            </button>
          ) : null}

          {/* {sessionStore.user ? (
            <div className="bg-green">
              <div
                className="d-flex text-white p-2 ml-3"
                style={{ cursor: "pointer" }}
              >
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id="user-tooltip">
                      {sessionStore.user.profile.email}
                    </Tooltip>
                  }
                >
                  <span>
                    <AccountCircleIcon
                      color="inherit"
                      fontSize="small"
                      className=" mr-2"
                    />
                    {sessionStore.user.profile.given_name +
                      " " +
                      sessionStore.user.profile.family_name}
                  </span>
                </OverlayTrigger>
              </div>
            </div>
          ) : null} */}
        </nav>
      </div>
      <Modal
        size="xl"
        show={viewContactModal}
        onHide={() => setViewContactModal(false)}
      >
        <Modal.Body>
          <ContactMe submitted={() => setViewContactModal(false)} />
        </Modal.Body>
      </Modal>
    </aside>
  );
});
